import { atom } from 'nanostores';

const $contentItems = atom<string[]>([]);

export function excludeContentItem(contentItem: string) {
    $contentItems.set([...$contentItems.get(), contentItem]);
}

export function getContentItemsToExclude() {
    return $contentItems.get();
}
