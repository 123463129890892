import { log } from '@innhold/core/logger';
import { hasConsentFor } from '@innhold/core/privacy';
import { removeTrackingPixelFromHtml } from '../tracking';
import type { Content } from '../types';

function getContentUrl(placement: string, itemsToExclude: string[]): string {
    const baseUrl = `https://dr-edition-server.inventory.schibsted.io/editions?editionName=e24-${placement}`;
    const itemsToExcludeQuery =
        itemsToExclude.length > 0
            ? `&excludedItems=${JSON.stringify(itemsToExclude)}`
            : '';

    return itemsToExcludeQuery ? `${baseUrl}${itemsToExcludeQuery}` : baseUrl;
}

export async function fetchContentForPlacement(
    placement: string,
    { itemsToExclude = [] }: { itemsToExclude?: string[] } = {}
): Promise<Content> {
    try {
        const response = await fetch(getContentUrl(placement, itemsToExclude));
        if (!response.ok) {
            throw new Error(
                `Fetching content for ${placement} failed with status ${response.status}`
            );
        }

        const content: Content = await response.json();

        const isValidContent = Object.keys(content).length > 0;
        if (!isValidContent) {
            return null;
        }

        const isAdvertisingConsentGiven = await hasConsentFor('CMP:advertising');
        if (!isAdvertisingConsentGiven) {
            const { mobileHTML, desktopHTML } = content;
            content.mobileHTML = removeTrackingPixelFromHtml(mobileHTML);
            content.desktopHTML = removeTrackingPixelFromHtml(desktopHTML);
        }

        return content;
    } catch (error) {
        log.error(`Error! Failed to fetch content for ${placement}!`, error);
        return null;
    }
}
