import { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import { InView } from 'react-intersection-observer';
import {
    excludeContentItem,
    getContentItemsToExclude,
} from '@innhold/core/ads/stores/content-items-store';
import { getDeviceTypeFromRoot } from '@innhold/core/device/client';
import { getStaticAssetUrl } from '@innhold/lib/assets/getStaticAssetUrl';
import { fetchContentForPlacement } from './api/fetchContentForPlacement';
import { trackContentClick, trackContentView } from './tracking';
import type { Content } from './types';
import { isVideo } from './utils/content';
import { prepareSvpPlayerScript } from './utils/svp/script';
import css from './AdContentMarketing.module.css';

export const AdContentMarketing = ({
    placement,
    fallback,
    isFullWidth,
}: {
    placement: string;
    fallback?: ReactNode;
    isFullWidth?: boolean;
}) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const [content, setContent] = useState<Content>(null);
    const [isContentLoading, setIsContentLoading] = useState(true);

    const isVideoContent = isVideo(content);

    useEffect(() => {
        const getContent = async () => {
            const contentForPlacement = await fetchContentForPlacement(placement, {
                itemsToExclude: getContentItemsToExclude(),
            });

            if (contentForPlacement) {
                if (isVideo(contentForPlacement)) {
                    await prepareSvpPlayerScript();
                }

                excludeContentItem(contentForPlacement.itemId);
                setContent(contentForPlacement);
            }

            setIsContentLoading(false);
        };

        getContent();
    }, [placement]);

    useEffect(() => {
        if (isVideoContent) {
            const initVideoPlayer = async () => {
                const { initSvpPlayer } = await import('./utils/svp/init');
                const { videoConfiguration: svpConfig } = content;

                initSvpPlayer(svpConfig);
            };

            initVideoPlayer();
        }
    }, [content, isVideoContent]);

    if (!content) {
        return (
            <>
                {isContentLoading ? <div className={css.contentLoader} /> : null}
                <div
                    className={css.contentFallback}
                    style={{ display: isContentLoading ? 'none' : 'block' }}
                >
                    {fallback}
                </div>
            </>
        );
    }

    const deviceType = getDeviceTypeFromRoot();
    const {
        pulseData: { algorithm, clientName, creativeId, editionId, itemId, url },
    } = content;

    return (
        <InView
            className={clsx('content-marketing-item', css.content, {
                [css.fullWidth]: isFullWidth,
            })}
            style={{ position: 'relative', width: '100%' }}
            data-content-placement={placement}
            data-algorithm={algorithm}
            data-client-name={clientName}
            data-creative-id={creativeId}
            data-edition-id={editionId}
            data-item-id={itemId}
            data-url={url}
            triggerOnce
            onChange={(inView, entry) => {
                if (inView) {
                    const root = entry.target as HTMLDivElement;
                    rootRef.current = root;
                    trackContentView(root, placement, content);
                }
            }}
            onClick={() => {
                const { current: root } = rootRef;
                trackContentClick(root, placement, content);
            }}
        >
            {isVideoContent ? (
                <link
                    rel="stylesheet"
                    type="text/css"
                    href={getStaticAssetUrl('/svp/content-marketing-player.css')}
                />
            ) : null}
            <div
                style={{ width: '100%' }}
                dangerouslySetInnerHTML={{
                    __html: content[
                        deviceType === 'mobile' ? 'mobileHTML' : 'desktopHTML'
                    ],
                }}
            />
        </InView>
    );
};
