import type { EventInputObject, EventInputTarget } from '@e24/mbbs';
import { getDeviceTypeFromRoot } from '@innhold/core/device/client';
import { getPulse } from '@innhold/core/tracking/pulse';
import type { Content } from './types';
import { getPartnerstudioSubtitle, getPartnerstudioTitle } from './utils/partnerstudio';

const TRACKING_PIXEL_PATTERN = /<img [^>]*class="tracking-pixel"[^>]*>/g;

export function removeTrackingPixelFromHtml(htmlContent: string) {
    return htmlContent.replace(TRACKING_PIXEL_PATTERN, '');
}

const normalizePlacementTitle = (title: string) => {
    return title.replace(/<[^>]+>/gi, '').replace(/[^a-z0-9æøå\s_-]/gi, '');
};

const getEventBaseData = (placement: string, content: Content): EventInputObject => {
    const {
        itemId,
        pulseData: { clientName, creativeId },
    } = content;

    return {
        id: placement,
        type: 'brandstudio-native',
        name: placement,
        position: placement,
        custom: {
            customer: clientName,
            creativeId: creativeId ?? itemId,
        },
    };
};

const getEventPartnerstudioData = (
    root: HTMLElement,
    placement: string,
    content: Content
) => {
    const device = getDeviceTypeFromRoot();
    const {
        pulseData: { algorithm, clientName, creativeId, editionId, itemId, url },
    } = content;
    const time = Math.floor(Date.now() / 1000);
    const title = getPartnerstudioTitle(root);
    const subtitle = getPartnerstudioSubtitle(root);

    return {
        algorithm,
        clientName,
        creativeId,
        device,
        editionId,
        itemId,
        link: url,
        placement,
        subtitle,
        time,
        title,
        type: 'click',
        tracking_type: 'ad',
        url,
        xandrAdLoaded: false,
    };
};

const getEventTarget = (content: Content): EventInputTarget => {
    const {
        itemId,
        pulseData: { title, url },
    } = content;

    return {
        id: itemId,
        name: normalizePlacementTitle(title),
        type: 'Page',
        url,
    };
};

const getEventInput = (root: HTMLElement, placement: string, content: Content) => {
    return {
        object: getEventBaseData(placement, content),
        partnerstudio: getEventPartnerstudioData(root, placement, content),
        target: getEventTarget(content),
    };
};

export async function trackContentView(
    root: HTMLElement,
    placement: string,
    content: Content
) {
    const pulse = await getPulse();
    pulse.trackElementView(getEventInput(root, placement, content));
}
export async function trackContentClick(
    root: HTMLElement,
    placement: string,
    content: Content
) {
    const pulse = await getPulse();
    pulse.trackElementClick(getEventInput(root, placement, content));
}
