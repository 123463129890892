import { log } from '@innhold/core/logger';
import { getExternalScript } from '@innhold/lib/scripts/getExternalScript';

export async function prepareSvpPlayerScript() {
    const isSvpPlayerReady = Boolean(window.SVP?.Player);
    if (isSvpPlayerReady) {
        return true;
    }

    try {
        return await getExternalScript(
            'https://cdn.stream.schibsted.media/player/3.61.0/player.next.min.js'
        );
    } catch (error) {
        log.error('Error! Failed to prepare SVP player script!', error);
        return false;
    }
}
